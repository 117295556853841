import CreatePatient from '@/views/patient/components/crm-create';
import UpdatePatient from '@/views/patient/components/crm-full-update';
import AddPartnerDoctor from '@/views/scheduler/add-partner-doctor'
import CreatePatientService from '@/views/scheduler/create-patient-service';
import { mapGetters, mapActions } from "vuex";

export default {
    props: ['drawer','open'],
    components: {
        CreatePatient,
        UpdatePatient,
        AddPartnerDoctor,
        CreatePatientService,
    },
    data() {
        return {
            activeTab: 'first',
            waitingPrint: false,
            waiting: false,
            search: '',
            yes_user: false,
            user: null,
            form: {},
            query: { relation : true },
	        patientServiceId: '',
            loadingData: false,
            selectedPatient: null,
            drawerPartner: false,
            drawerCreatePatient: false,
            drawerUpdatePatient: false,
            drawerPatientService: false,
            latestServices: [],
        }
    },
    computed: {
    	...mapGetters({
            list: 'services/selected_list',
            selected_partner_doctor: 'partnerDoctor/selected_partner_doctor',
            partnerDoctors: 'partnerDoctor/list',
            services: 'services/relation_list',
            servicesSelected: 'services/selected_relation_list',
            patients: 'patients/search_list',
            modelPatient: 'patients/model',
            modelService: 'services/model',
            rooms: 'rooms/list',
            conditions: 'conditions/list',
            model: 'orders/model',
            money: 'money',
        }),
        totalAmount: function(){
            return this.list.reduce((a,b) => a + (b.count * (b.price - ((b.price * b.discount_price) / 100)) || 0), 0 );
        },
         totalDiscount: function() {
            return this.list.reduce((a,b) => a + (b.price * b.discount_price / 100 || 0), 0 );
        }
    },
    mounted() {
        if (this.services && this.services.length === 0) this.servicesRealtionList(this.query)
        if (this.servicesSelected && this.servicesSelected.length === 0) this.servicesSelectedRealtionList({selected_relation: true})
        if (this.partnerDoctors && this.partnerDoctors.length === 0) this.partnerDoctorsList()
        if (this.rooms && this.rooms.length === 0) this.loadRooms()
        if (this.conditions && this.conditions.length === 0) this.loadConditions()

        this.fetchPatients = _.debounce(this.loadFilteredPatients,500)
    },
    watch: {

    },
    methods: {
		...mapActions({
            updateSelectedListItem: 'services/updateSelectedItem',
            partnerDoctorsList: 'partnerDoctor/index',
            servicesRealtionList: 'services/relationList',
            servicesSelectedRealtionList: 'services/selectedRelationList',
            updatePatients: 'patients/search',
            loadRooms: "rooms/index",
            loadConditions: "conditions/index",
            saveList: 'services/setlist',
            save: 'orders/store',
            emptyList: 'services/emptyList',
            emptySearch: 'patients/emptySearch',
            lastPatientId: 'patients/lastIdPatent',
            printAction: 'orders/print',
            removeSelectedList:'services/removeSelectedList',
            loadPatientLastOrderServices: 'patients/loadPatientLastOrderServices'
        }),
        ifValidationError(err){
        	 if(err.code == 422){
                let errors = ''
                for (var k in err.error[0]){
                    if (err.error[0].hasOwnProperty(k)) {
                        errors = errors + err.error[0][k][0] + '\n';
                    }
                }
                this.$notify({
                    title: 'Ошибка ',
                    type: "error",
                    position: "bottom right",
                    message: errors
                });
            }
            else{
                this.$alert(err);
            }
        },
        noUser(yes = false){
            this.yes_user = yes;
        },
        searchPatients() {
            this.fetchPatients()
        },
        loadFilteredPatients(){
            const query = { search : this.search };
            if (!this.loadingData) {
                this.loadingData = true;
                this.updatePatients(query).then(res => {
                    this.noUser();
                    this.user = {}
                    this.latestServices = [];
                    this.loadingData = false
                }).catch(err => {
                    this.loadingData = false
                });
            }
        },
        tenMinMin(time){
            var result = new Date()
            result.setHours(time.split(":")[0],time.split(":")[1])
            result.setMinutes(result.getMinutes() - 10);
            return this.checkTime(result.getHours()) + ':' + this.checkTime(result.getMinutes())
        },
        checkTime(i) {
            return (i < 10) ? "0" + i : i;
        },
        calendarChanged() {
            if (_.isFunction(this.parent().listChanged))
            {
                
                
                this.parent().listChanged() 
            }
           
        },
        parent(){
            return this.$parent.$parent
        },
        openUpdatePatient(model)
        {
            this.selectedPatient = model;
            this.drawerUpdatePatient = true;
        },
         editRow(row,event,key){
            let data = {id: row.id, value : event, key: key}
            this.updateSelectedListItem(data)
                .then(res => {
                }).catch(err => {
                })
        },
        deleteRow(scope) {
            this.$confirm('Вы уверены, что хотите удалить его?', 'Предупреждение', {
                confirmButtonText: 'Да',
                cancelButtonText: 'Отмена',
                type: 'warning'
            })
            .then(() => {
                this.removeSelectedList(scope.id)
                    .then(res => {
                        this.$message({
                            type: 'success',
                            message: 'Удалить завершено'
                        });
                    }).catch(err => {

                    });
            })
            .catch(() => {
                this.$message({
                    type: 'info',
                    message: 'Удалить отменено'
                });
            });
        },
        close(){
            this.empty();
            this.parent().closeChildDrawer(this.drawer);
        },
        async closeDrawerAddPartnerDoctor(obj){
            if (obj.reload){
                await this.partnerDoctorsList()
                this.form.partner_doctor_id = this.selected_partner_doctor.id
            }
            if (obj.drawer){
                this[obj.drawer] = false
            }
        },
        setPatient(patient){
            this.user = JSON.parse( JSON.stringify( patient ));
            this.noUser(true);
        },
        closeDrawerPatient(obj){
            if (obj.reload){
                this.setPatient(this.modelPatient);
            }
            if (obj.drawer){
                this[obj.drawer] = false
            }
        },
        closeDrawerPatientService(obj){
            if (obj.drawer){
                this[obj.drawer] = false
            }
        },
        closeDrawerUpdatePatient(obj){
            if (obj.reload){
                this.setPatient(obj.patient)
            }
            if (obj.drawer){
                this[obj.drawer] = false;
            }
        },
        edit(model){
            this.user = JSON.parse( JSON.stringify( model ));
            this.noUser(true);

            this.latestServices = [];
            this.loadPatientLastOrderServices(model.id)
            .then(res => {
                this.latestServices = res.data;
            })
            .catch(err => {
                console.log(err);
            });
        },
        addService(){
            let vm = this;
            let service = this.servicesSelected.find(function(item) {
                return item.id == vm.patientServiceId;
            })
            if (service) {
                this.saveList([JSON.parse( JSON.stringify( service ))])
                    .then(res => {
                    }).catch(err => {
                });
            }
        },
        changeWaiting(def = false){
            this.waiting = def;
        },
        getChildrens: function(children){
            if (children) return children.filter((item) => { return item.is_service == true});
            return [];
        },
        async addPatient(){
            await this.$store.dispatch('patients/empty');
            this.lastPatientId()
            .then(res => {
                this.drawerCreatePatient = true;
            }).catch(err => {
                this.drawerCreatePatient = true;
            });
        },
        print(id) {
            this.waitingPrint = true;
            let vm = this;
            var jqxhr = $.get( process.env.VUE_APP_URL + `orders/print?id=${id}`, function() {})
            .done(function(res) {
                vm.waitingPrint = false;
                const WinPrint = window.open("","","left=0,top=0,toolbar=0,scrollbars=0,status=0");
                WinPrint.document.write(res);
                WinPrint.document.close();
                WinPrint.focus();
                setTimeout(() => {
                    WinPrint.print();
                    WinPrint.close();
                }, 1000);
            })
            .fail(function() {
                alert( "Не может печататься" );
                vm.waitingPrint = false;
            })
        },
    },
}